import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoadUserInfo from "@/views/LoadUserInfo";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home1',
        component: LoadUserInfo
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView
    }, {
        path: '/loadUserInfo',
        name: 'loadUserInfo',
        component: LoadUserInfo
    },
    {
        path: '/lookChina',
        name: 'lookChina',
        component: () => import('../views/LookChina.vue')
    },
    {
        path: '/ShareAround',
        name: 'shareAround',
        component: () => import('../views/ShareAround.vue')
    },
    {
        path: '/ShareCourse',
        name: 'ShareCourse',
        component: () => import('../views/ShareCourse.vue')
    },
    {
        path: '/ShareCourseTest',
        name: 'ShareCourseTest',
        component: () => import('../views/ShareCourseTest.vue')
    }
]
const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.afterEach((to, from) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

export default router
