import Vue from 'vue'
import Vuex from 'vuex'
import {getWeChatUserByToken, getWechatUserInfo} from '@/request/api.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        common: {
            token: localStorage.getItem('tokens') || null,
            userInfo: {
                openid: null,
            },
            id: null,
        }
    },
    getters: {},
    mutations: {},
    actions: {
        async shareId(state, id) {
            if (id) {
                state.common.id = id
            }
        },
        // 获取根据token获取用户信息
        async getInfoA({commit, state}) {
            try {
                let data = {
                    access_token: localStorage.getItem('tokens'),    
                    publicOpenid: localStorage.getItem("openidUser"),
                }
                // alert(JSON.stringify(data))
                let res = await getWeChatUserByToken(data)
                return res
            } catch (ex) {
                alert('getInfoAError:' + ex);
            }
        },
        // 获取根据code获取用户信息
        async getInfoByCode({commit, state}, code) {
            let obj = {
                code: code
            }
            let res = await getWechatUserInfo(obj)
            // commit('SET_TOKEN', res.data)
            return res
        }
    },
    modules: {}
})
