<template>
  <div id="app">
    <!--    这里是APP模块-项目首页&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;↓↓↓↓↓
        <div>
          微信配置对象：{{ pageWxConfig }}
        </div>
        <div>
          微信用户信息1:{{ wxUserInfo1 }}
        </div>
        <div>
          微信用户信息2:{{ wxUserInfo2 }}
        </div>
        这里是APP模块-项目首页&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;↑↑↑↑↑-->
    <router-view />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getWxConfig } from "@/request/api";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      nowPageLink: "",
      showPageUserOpenId: "",
      pageWxConfig: null,
      wxUserInfo1: null,
      wxUserInfo2: null,
    };
  },
  computed: {
    //展开vuex的store中的计算属性
    ...mapState({
      common: (state) => state.common,
    }),
  },
  created() {
    // alert("app授权后回调路径url:" + location.href)
    //app组件里记录下是哪个url的授权回调
    window.localStorage.setItem("scanUrl", location.href); //.split('#')[0]
  },
  mounted() {
    this.initWechatJS_SDK()
    // if (!localStorage.getItem("tokens")) {
    //   // alert("app全局判定token为空，重新获取");
    //   this.initWechatJS_SDK();
    // } else {
    //   // this.initWechatJS_SDK();
    //   // alert("token已不为空:" + localStorage.getItem('tokens'))
    //   this.initWechatJS_SDK()
    // }
  },
  methods: {
    //展开vuex中store中的公共函数
    ...mapActions(["getInfoByCode", "getInfoA", "shareId"]),
    //用户进入公众号项目 第一步 初始化配置
    initWechatJS_SDK() {
      //记录链接?
      this.nowPageLink = /(Android)/i.test(navigator.userAgent)
        ? location.href
        : window.localStorage.getItem("scanUrl");
      // alert(this.nowPageLink)
      //记录回调链接?
      let obj = {
        jsUrl: this.nowPageLink,
      };
      getWxConfig(obj)
        .then((data) => {
          let that = this;
          if (data.code === 200) {
            this.pageWxConfig = data.data.WxConfig;
            //appid存到localStore
            localStorage.setItem("appid", data.data.WxConfig.appid);
            //公众号项目 设置wxConfig
            this.$wx.config({
              debug: false, // TODO: 测试阶段使用
              appId: this.pageWxConfig.appid,
              timestamp: this.pageWxConfig.timestamp,
              nonceStr: this.pageWxConfig.nonceStr,
              signature: this.pageWxConfig.signature,
              jsApiList: [
                "onMenuShareAppMessage",
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "getNetworkType",
                "getLocation",
                "onMenuShareTimeline",
                "onMenuShareQQ",
                "onMenuShareQZone",
              ], // 需要使用的API列表
            });
            // alert(localStorage.getItem('scanUrl'))
            // this.$wx.ready(() => {   
                // that.$wx.onMenuShareAppMessage({
                //   title: "焱火体育幸运大转盘", // 分享标题
                //   desc: "焱火体育幸运大转盘",
                //   link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                //   type: "link",
                //   imgUrl:
                //     "https://codeimg.yanhuotiyu.com/activity/header_m_img/YanHuoLogo.png", // 分享图标
                //   success: function () {
                //     // 设置成功
                //     alert("分享成功c");
                //   },
                //   cancel: function () {
                //     alert("取消分享C");
                //   },
                // });        
              // that.$wx.getNetworkType({
              //   success: function (res) {
              //     var networkType = res.networkType;
              //     localStorage.setItem("network", networkType);
              //     console.log("network", networkType);
              //     // 返回网络类型2g，3g，4g，wifi
              //   },
              // });
              //alert('进入ready')
              // 微信JS-SDK配置成功后，可以在这里调用微信的API
            // });
            //alert("进入ready下的")
            // let token = localStorage.getItem("tokens");
            let user_info = JSON.parse(localStorage.getItem("user_info"))
            if (!user_info) {
              // alert("token11:" + token)
              //首次token为空 则跳转去 授权
              this.wxAuthorize();
            } else {
              //alert("token22:" + token)
              // 获取最新用户信息
              this.getInfoA().then((res) => {
                this.wxUserInfo2 = res;
                if (res.code === 200) {
                  this.common.userInfo = res.data;
                  localStorage.setItem("user_info", JSON.stringify(res.data));
                } else {
                  this.wxAuthorize();
                }
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //已获取过code,不是首次进入页面 则直接初始化数据
    // initData() {
    //   this.nowPageLink = /(Android)/i.test(navigator.userAgent)
    //     ? location.href
    //     : window.localStorage.getItem("scanUrl");
    //   //alert("thisLink:" + this.nowPageLink);
    //   let obj = {
    //     jsUrl: this.nowPageLink,
    //   };
    //   this.$toast(localStorage.getItem("scanUrl"));
    //   getWxConfig(obj).then((data) => {
    //     //因为ready中会替换this 将this提前转存
    //     let that = this;
    //     //因为ready中会替换this 将this提前转存 方法2 监视 分享
    //     let nowPageThis = this;
    //     this.pageWxConfig = data.data.WxConfig;
    //     if (data.code === 200) {
    //       localStorage.setItem("appid", this.pageWxConfig.appid);
    //       this.$wx.config({
    //         debug: false, // TODO: 测试阶段使用
    //         appId: this.pageWxConfig.appid,
    //         timestamp: this.pageWxConfig.timestamp,
    //         nonceStr: this.pageWxConfig.nonceStr,
    //         signature: this.pageWxConfig.signature,
    //         jsApiList: [
    //           "onMenuShareAppMessage",
    //           "updateAppMessageShareData",
    //           "updateTimelineShareData",
    //           "getNetworkType",
    //           "getLocation",
    //           "onMenuShareTimeline",
    //           "onMenuShareQQ",
    //           "onMenuShareQZone",
    //         ],
    //       });
    //       //方法1监视分享 谁先绑定谁生效
    //       that.$wx.ready(function () {
    //         that.$wx.onMenuShareAppMessage({
    //           title: "测试分享标题", // 分享标题
    //           desc: "测试嘎嘎嘎",
    //           link: `https://web.yanhuotiyu.com/#/ShareAround`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //           type: "link",
    //           imgUrl: "https://codeimg.yanhuotiyu.com/MinProgramImg/code.png", // 分享图标
    //           success: function () {
    //             // 设置成功
    //             alert("分享成功c");
    //           },
    //           cancel: function () {
    //             alert("取消分享C");
    //           },
    //         });
    //         that.$wx.getNetworkType({
    //           success: function (res) {
    //             var networkType = res.networkType;
    //             localStorage.setItem("network", networkType);

    //             // 返回网络类型2g，3g，4g，wifi
    //           },
    //         });
    //       });
    //       this.getInfoA().then((res) => {
    //         this.wxUserInfo2 = res;
    //         alert('res最新用户信息'+ JSON.stringify(res))
    //         if (res.code === 200) {
    //           this.common.userInfo = res.data;
    //           localStorage.setItem("user_info", JSON.stringify(res.data));
    //         } else {
    //           // localStorage.clear();
    //           this.wxAuthorize();
    //         }
    //       });
    //       //
    //       //方法1监视分享 谁嫌绑定谁生效
    //       /*//alert("page1--::::进入ready:")
    //       nowPageThis.$wx.ready(function () {
    //         //ready之后 监听 3个分享事件?
    //         //alert("进入page1--的ready:")
    //         nowPageThis.$wx.onMenuShareAppMessage({
    //           title: 'page1--分享1', // 分享标题
    //           desc: "page1--分享1",
    //           link: `https://web.yanhuotiyu.com?id=${this.title}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //           type: 'link',
    //           imgUrl: 'https://codeimg.yanhuotiyu.com/MinProgramImg/code.png', // 分享图标
    //           success: function () {
    //             // 设置成功
    //             //alert('page1的分享成功111')
    //           },
    //           cancel: function () {
    //             //alert('page2的取消分享d')
    //           }
    //         })

    //       })*/
    //     }
    //   });
    // },

    //调用微信公众号内提示用户去授权
    wxAuthorize() {
      //alert("进入:wxAuth" + location.href)
      let arr1 = location.href;
      let code = "";
      if (arr1.indexOf("?") > 0) {
        let arr2 = arr1.split("?")[1].split("&")[0].split("=");
        code = arr2[1];
      }
      // let state = this.$route.query.state
      // 判断token调用授权接口，没有拿到就跳转微信授权链接获取
      if (code && code.length > 10) {
        localStorage.setItem("code", code);
        this.getInfoByCode(code).then((res) => {
          if (res.code === 200) {
            this.wxUserInfo1 = res.data;
            //alert("获取到用户信息!!getInfoByCode:Res:" + res.data.openid)
            localStorage.setItem("tokens", res.data.access_token);
            localStorage.setItem("user_info", JSON.stringify(res.data));
            localStorage.setItem("openidUser", res.data.openid);
          } else {
            // localStorage.clear();
            // 如果 通过code调用失败 则调起授权 若再次失败   =--先不考虑
            let appid = localStorage.getItem("appid") || null;
            this.nowPageLink = encodeURIComponent(this.nowPageLink);
            window.location.href =
              " https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              appid +
              `&redirect_uri=  ${this.nowPageLink}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
          }
        });
      } else {
        // localStorage.clear();
        //alert("进入code的Else" + code);
        // console.log(this.nowPageLink, "2");
        let appid = localStorage.getItem("appid") || null;
        this.nowPageLink = encodeURIComponent(this.nowPageLink);
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          appid +
          `&redirect_uri=${this.nowPageLink}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
      }
    },
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
