<template>
  <div>
<!--    页面容器块-->
    <div class="container">
      <div ><img class="img" src="../assets/HeaderImage01.jpg"></div>
      <div style="width: 100%;height: 8vh;">
        <div
            style="background: -webkit-linear-gradient(left,#eb4512,#f02416);width:70%;text-align:left;color: #fff;padding-left:20px ;">
          <div style="height:2vh;visibility: hidden;">s</div>
          <div>距离结束：</div>
          <div>
            <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒" style="color: #fff;"/>
          </div>
        </div>
        <div style="background:rgb(255, 235, 235);flex:1;color: red;font-weight: bold;">
          <div style="height:2vh;visibility: hidden;">s</div>
          <div>已抢</div>
          <div>128</div>
        </div>
      </div>
      <div
          style="width:100vw;padding:20px 0;background:orangered;text-align: center;font-weight: bold;color: #fff;font-size: large;">
        <a href="https://channels.weixin.qq.com/shop/a/AeeDpLJoEstKJ2s" target="_blank">立即购买</a>
      </div>
      <div><img class="img" src="../assets/BottomImage01.jpg"></div>
    </div>
    <!--    按钮区域-->
    <!--    <button @click="getNetworkType">获取网络状态</button>
        <button @click="getLocation">获取当前地理位置</button>
        <button @click="getNetOnCity">获取省市县</button>-->
    <!--    网络请求结果-显示区域-->
    <!-- <div>
      T：<p id="tempShowLabel"></p>
    </div> -->
    <!--    文章 -->
    <!--    <div class="wechat-article">
          <h1 class="wechat-article__title">{{ title }}</h1>
          <div v-html="content" class="wechat-article__content"></div>
        </div>-->

    <!--  漂浮音频按钮-->
    <div>
      <van-image :src="require('../assets/music001.png')"
                 id="targetElement"
                 style="position:absolute;z-index: 99;top: 6%;height: 30px;width: 30px;right: 3%;"
                 :class="[playAudio?'audio':'noAudio']"
                 @click="noAudio"
      />
      <audio id="bg-music" ref="myMap3Player" src="../assets/music001.mp3" autoplay="autoplay" controls
             style="position: absolute;top: 0; z-index: 0;opacity: 0;"></audio>
    </div>

    <!--    中间漂浮--距离结束多少秒-->
    <div style="display:flex;width: 100%;position:absolute;top: 33%;height: 8vh;z-index: 99;">
      <div
          style="background: -webkit-linear-gradient(left,#eb4512,#f02416);width:70%;text-align:left;color: #fff;padding-left:20px ;">
        <div style="height:2vh;visibility: hidden;">s</div>
        <div>距离结束：</div>
        <div>
          <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒" style="color: #fff;"/>
        </div>
      </div>
      <div style="background:rgb(255, 235, 235);flex:1;color: red;font-weight: bold;">
        <div style="height:2vh;visibility: hidden;">s</div>
        <div>已抢</div>
        <div>128</div>
      </div>

    </div>
    <!--    漂浮 立即购买按钮-->
    <div style="display:flex;width: 100%;position:absolute;top: 41%;height: 8vh;z-index: 99;">
      <div
          style="width:100vw;padding:20px 0;background:orangered;text-align: center;font-weight: bold;color: #fff;font-size: large;">
        <a href="https://channels.weixin.qq.com/shop/a/AeeDpLJoEstKJ2s" target="_blank">立即购买</a>
      </div>
    </div>

    <!--    底部购买按钮-->
    <div style="position:fixed;bottom:0;z-index: 1000;" @click="goBuy">
      <div
          style="width:100vw;padding:20px 0;background:orangered;text-align: center;font-weight: bold;color: #fff;font-size: large;">
        <a href="https://channels.weixin.qq.com/shop/a/AeeDpLJoEstKJ2s" target="_blank">立即购买</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import axios from "axios";


export default {
  data() {
    return {
      netCity: '',
      title: '文章标题',
      content: `
        <p>这里是文章内容，可以包含各种标签和样式。</p>
        <p>比如这里是一个加粗的文字：<strong>加粗文字</strong></p>
      `,
      time: 60 * 60 * 24 * 3,
      playAudio: true,
      isAndroid: null,
      isIos: null,
      myUserOpenId: null,
    };
  },
  created() {
    //alert("page2::::loadUserInfo页面授权后回调路径url:" + location.href)
  },
  //销毁生命周期前
  beforeDestroy() {
    document.getElementById('bg-music').pause()
  },
  //dom初始化完毕后
  mounted() {
    document.title = "焱火体育,为体育而生,为梦想而战!";
    this.initNowPageWxCallBack()
  },
  computed: {
    ...mapState({
      common: (state) => state.common,
    }),
  },
  methods: {
    ...mapActions(['getInfoByCode', 'getInfoA', 'shareId']),
     // 点击抽奖按钮会触发star回调
    //获取网络状态
    getNetworkType() {
      this.$wx.getNetworkType({
        success: function (res) {
          const networkType = res.networkType; // 返回网络类型2g，3g，4g，wifi
          //alert("page2::::网络类型:" + networkType)
        }
      });
    },
    //获取地理位置
    getLocation() {
      this.$wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          alert(res.latitude + "," + res.longitude)
          // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          // var speed = res.speed; // 速度，以米/每秒计
          // var accuracy = res.accuracy; // 位置精度
        }
      });
    },

    //获取当前网络所处地区
    getNetOnCity() {
      axios.get(`https://ip.useragentinfo.com/json`).then((res) => {
        this.netCity = res.data
        alert('当前网络所处地区:' + res.data.city)
        localStorage.setItem('city', this.netCity)
      })
    },

    //初始化 当前页面 回调
    initNowPageWxCallBack() {
      //谁先给对象$wx绑定回调 谁生效
      /*let that = this
      that.$wx.ready(function () {
        that.$wx.onMenuShareAppMessage({
          title: '测试分享标题22', // 分享标题
          desc: "测试嘎嘎嘎",
          link: `https://web.yanhuotiyu.com?id=${this.id}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          type: 'link',
          imgUrl: 'https://codeimg.yanhuotiyu.com/MinProgramImg/code.png', // 分享图标
          success: function () {
            // 设置成功
            //alert('分享成功c1')
          },
          cancel: function () {
            //alert('取消分享d2')
          }
        })
      })*/

      //ready之后 ---------------直接播放音乐???尝试
      const nowUserAgent = navigator.userAgent;
      this.isAndroid = nowUserAgent.indexOf('Android') > -1 || nowUserAgent.indexOf('Adr') > -1; //android终端
      this.isIos = !!nowUserAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      //另存一下
      let that = this;
      //监听触摸开始--有效
      document.addEventListener('touchstart', function () {
        //打印提示
        // document.getElementById("tempShowLabel").innerText += ("333:");
        //获取audio的dom并播放
        document.getElementById('bg-music').play();
        //执行了

        //dom下次更新后 执行$nextTick
        that.$nextTick(function () {
          console.log("$nextTick执行了")
        });
      });


      //添加页面事件监听2
      document.addEventListener("WeixinJSBridgeReady", function () {
        console.log("监听到事件执行:WeixinJSBridgeReady")
      }, false);

      //-------------------------------------------------------------尝试播放音乐结束

      //谁先给对象$wx绑定回调 谁生效
      let nowPageThis = this;
      nowPageThis.$wx.ready(function () {
        //ready之后 监听分享,CJL TEST 仅监听xx子页面的分享事件
        //alert("进入page2的ready:")
        nowPageThis.$wx.onMenuShareAppMessage({
          title: '焱火体育,体能恢复训练计划1', // 分享标题
          desc: "焱火体育,火爆招生中(^_^)",
          link: `https://web.yanhuotiyu.com/#/loadUserInfo?tempCode=${nowPageThis.myUserOpenId}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          type: 'link',
          imgUrl: 'https://codeimg.yanhuotiyu.com/activity/header_m_img/YanHuoLogo.png', // 分享图标
          success: function () {
            // 设置成功
            alert('T2:分享成功')
          },
          cancel: function () {
            // alert('page2:取消分享d')
          }
        })
      })
    },

    //视野跳转到购买按钮处
    goBuy() {
      if (this.isAndroid) {
        let height = document.documentElement.clientHeight * 0.283;
        window.scrollTo({
          top: height,
          behavior: 'smooth' // 平滑滚动效果，可选
        });
      }
      if (this.isIos) {
        console.log('B');
        let height = document.getElementById('targetElement').offsetTop
        window.scrollTo({
          top: height,
          behavior: 'smooth' // 平滑滚动效果，可选
        });
      }
    },

    //执行音乐播放?
    noAudio() {
      if (this.playAudio) {
        document.getElementById('bg-music').pause()
      } else {
        document.getElementById('bg-music').play()
      }
      this.playAudio = !this.playAudio
    },
  }
};
</script>
<style scoped>
audio {
  animation: rotate 3s linear infinite;
}

a {
  color: inherit;
  text-decoration: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  /* max-width: 100vw; */
  /* box-sizing:content-box; */
  overflow-x: hidden;  /* 防止图片宽度超出容器时出现水平滚动条 */
}

.img {
  max-width: 100%;
  height: auto; /* 保持图片高度自适应 */
}
</style>
