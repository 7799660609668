<template>
  <div>
    <div class="home" id="toTop" style="touch-action:pan-y;">
      <van-image :src="require('../assets/RedPackQrCode.png')" contain style="touch-action:pan-y;"/>
    </div>
    <van-image :src="require('../assets/music001.png')"
               id="targetElement"
               style="position:absolute;z-index: 99;top: 6%;height: 30px;width: 30px;right: 3%;"
               :class="[playAudio?'audio':'noAudio']"
               @click="noAudio"
    />
    <audio id="bg-music" ref="myMap3Player" src="../assets/music001.mp3" autoplay="autoplay" controls
           style="position: absolute;top: 0; z-index: 0;opacity: 0;"></audio>
    <div style="display:flex;width: 100%;position:absolute;top: 22%;height: 8vh;z-index: 99;">
      <div
          style="background: -webkit-linear-gradient(left,#eb4512,#f02416);width:70%;text-align:left;color: #fff;padding-left:20px ;">
        <div style="height:2vh;visibility: hidden;">s</div>
        <div>距离结束：</div>
        <div>
          <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒" style="color: #fff;"/>
        </div>
      </div>
      <div style="background:rgb(255, 235, 235);flex:1;color: red;font-weight: bold;">
        <div style="height:2vh;visibility: hidden;">s</div>
        <div>已抢</div>
        <div>128</div>
      </div>
    </div>
    <div style="position:fixed;bottom:0;z-index: 100;" @click="goBuy">
      <div
          style="width:100vw;padding:20px 0;background:orangered;text-align: center;font-weight: bold;color: #fff;">
        <a href="https://channels.weixin.qq.com/shop/a/2HcZjWeAWmOrYFs" target="_blank">立即购买</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      time: 60 * 60 * 24 * 3,
      playAudio: true,
      isAndroid: null,
      isIos: null,
    }
  },
  methods: {
    goBuy() {
      if (this.isAndroid) {
        let height = document.documentElement.clientHeight * 0.283;
        window.scrollTo({
          top: height,
          behavior: 'smooth' // 平滑滚动效果，可选
        });
      }
      if (this.isIos) {
        console.log('B');
        let height = document.getElementById('targetElement').offsetTop
        window.scrollTo({
          top: height,
          behavior: 'smooth' // 平滑滚动效果，可选
        });
      }

    },
    noAudio() {
      if (this.playAudio) {
        document.getElementById('bg-music').pause()
      } else {
        document.getElementById('bg-music').play()
      }
      this.playAudio = !this.playAudio
    },
  },
  beforeDestroy() {
    document.getElementById('bg-music').pause()
  },
  mounted() {
    const u = navigator.userAgent;
    this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    this.isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    const audio = document.getElementById('bg-music');
    audio.load()
    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise.then(function () {
        audio.play()
      }).catch(function () {
      })
    }
    //监听触摸开始?
    document.addEventListener('touchstart', function () {
      function audioAutoPlay() {
        let audio = document.getElementById('bg-music');
        audio.play();
      }

      audioAutoPlay();
    });
    //dom下次更新后 执行$nextTick
    this.$nextTick(function () {
      let video = this.$refs.myMap3Player;

      alert("对象是:" + window.WeixinJSBridge);
      if (
          typeof window.WeixinJSBridge == "object" &&
          typeof window.WeixinJSBridge.invoke == "function"
      ) {

        window.WeixinJSBridge.invoke("getNetworkType", {}, function (res) {
          video.play();
        });
      }
    });
    //添加页面事件监听
    document.addEventListener("WeixinJSBridgeReady", function () {
      alert("触发了:WeixinJSBridgeReady");
      let music = document.getElementById('bg-music');
      music.play();
    }, false);
  },
}
</script>
<style scoped>
audio {
  animation: rotate 3s linear infinite;
}
a {
  color: inherit;
  text-decoration: none;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
