import axios from "@/utils/http";

export const getWxConfig = (data) => axios(`/api/publicMark/loadWxConfigByJsUrl`, data, 'POST') //获取用户信息
export const getWechatUserInfo = (data) => axios(`/api/publicMark/publicLoginByCode`, data, 'POST') //微信授权通过code获取用户信息token
export const getWeChatUserByToken = (data) => axios(`/api/publicMark/loadUserByAccessToken`, data, 'POST') //获取用户信息
export const getCode = (data) => axios(`/api/publicMark/getSmsAuthCode`,data, 'GET') //转盘获取验证码
export const firstAddScanLog = (data) => axios(`/api/luckyPrizes/firstAddScanLog`, data,'POST') //公众号网页码进入立即加一条记录 
export const addScanLog = (data) => axios(`/api/luckyPrizes/addScanLog`, data,'POST')//公众号网页得到授权信息后的调用的接口 
export const getPageList = (data) => axios(`/api/luckyPrizes/getPageList`, data,'POST')//幸运大转盘-奖项列表 
export const drawLottery = (data) => axios(`/api/luckyPrizes/drawLottery`, data,'POST') //点击大转盘-开始抽奖 
export const checkSmsCodeLogin = (data) => axios(`/api/publicMark/checkSmsCodeLogin`, data,'POST')//公众号绑定手机号-携带验证码
// export const getWinningList = (data) => axios(`/api/luckySpinRecords/getPageList`, data,'POST')//幸运大转盘-抽奖记录分页列表 
export const loadLuckyRecords = (data) => axios(`/api/luckySpinRecords/loadLuckyRecords`, data,'POST')//幸运大转盘-个人抽奖记录
export const loadCourseBasicInfo = (data) => axios(`/api/CourseShare/loadCourseBasicInfo`, data,'POST')//约课进入时,获取课程相关基础信息 
export const addCourseStudent = (data) => axios(`/api/CourseShare/addCourseStudent`, data,'POST')//提交学生的基础信息-约课 
export const loadCoursePageByUserId = (data) => axios(`/api/CourseShare/loadCoursePageByUserId`, data,'POST')//某用户UserId的约课记录 

